import {Moment} from 'moment';

export interface UserData {
  username: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  toke_type: string;
}

export interface Creative {
  id: number;
  name: string;
  duration: number;
  type: CreativeType;
  file_name: string;
  src: string;
  width: number;
  src_prev: string;
  height: number;
  extension?: string;
  selected?: boolean;
}

export enum CreativeType {
  Image = 'image',
  Video = 'video'
}

export interface PlayList {
  id?: number;
  name: string;
  datetime_start?: Moment;
  datetime_stop?: Moment;
  blocks: PlayListBlock[];
  is_draft?: boolean;
}

export interface PlayListBlock {
  id?: number;
  title: string;
  displays: DisplayItem[];
  isOpened?: boolean;
  fillStatus?: FillStatus;
  copyAvailable?: boolean;
}

export interface DisplayItem {
  id?: number;
  title: string;
  creatives: Creative[];
}

export enum FillStatus {
  Empty = 'empty',
  Partial = 'partial',
  Full = 'full'
}

export interface CalendarDay {
  date: Moment;
  times: CalendarHour[];
}

export interface CalendarHour {
  datetime_start: Moment;
  datetime_stop: Moment;
  name: string;
  plid: string;
  time: string;
  className?: string;
}

export interface ReportData {
  url: string;
}

export interface BackEndResponse <T> {
  data: T;
  meta: MetaDataResponse;
  status: StatusResponse;
}

export interface MetaDataResponse {
  itemsCount: number;
  page: number;
  perPage: number;
  totalPages: number;
}

export interface StatusResponse {
  code: number;
  message: string;
}

export interface LogItem {
  id: number;
  message: number;
  computer_id: number;
  created_at: string;
  readed_at: string;
}

export interface ConvertQueueItem {
  id: number;
  queue_id: number;
  name: string;
  start: Moment;
  stop: Moment;
  status: number;
  status_msg: string;
  message: string;
  created_at: Moment;
}
