import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {UserData} from '../../interfaces/main.interfaces';
import {Router} from '@angular/router';
import {takeWhile} from 'rxjs/internal/operators';
import {ApiService} from '../../services/api.service';
import {noop, get} from 'lodash';

@Component({
  selector: 'ant-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss']
})
export class MainContainerComponent implements OnInit, OnDestroy {
  public userData: UserData = null;
  public countOfUnread = 0;

  private alive = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private apiService: ApiService
  ) {}

  public ngOnInit(): void {
    this.authService.userDataSubject
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: UserData) => {
        this.userData = data;
      });

    this.initUnreadCountFetching();
  }

  public ngOnDestroy(): void {
    this.alive = false;
    this.apiService.stopFetchingUnreadAmount();
  }

  public signOut(): void {
    this.authService.signOut()
      .subscribe(() => {
        this.router.navigate(['/login']);
      });
  }

  public initUnreadCountFetching(): void {
    this.apiService.startFetchingUnreadAmount()
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response: {data: {quantity: string}}) => {
          this.countOfUnread = Number(get(response, 'data.quantity', '0'));
        },
        noop
      );
  }
}
