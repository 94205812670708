import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {UnauthInterceptor} from './core/interceptors/unauth.interceptor';
import {TokenInterceptor} from './core/interceptors/token.interceptor';
import {MainContainerComponent} from './core/components/main-container/main-container.component';
import {MatDialogModule} from '@angular/material';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {PreviewModalComponent} from './core/components/preview-modal/preview-modal.component';
import {DatePickerModalComponent} from './core/components/date-picker-modal/date-picker-modal.component';
import {DlDateTimeMomentModule, DlDateTimePickerModule} from 'angular-bootstrap-datetimepicker';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    MainContainerComponent,
    PreviewModalComponent,
    DatePickerModalComponent
  ],
  entryComponents: [
    PreviewModalComponent,
    DatePickerModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    DlDateTimeMomentModule,
    DlDateTimePickerModule,
    AngularFontAwesomeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
