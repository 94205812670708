import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Creative, CreativeType} from '../../interfaces/main.interfaces';
@Component({
  selector: 'ant-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss']
})

export class PreviewModalComponent implements OnInit {
  public creative: Creative = null;

  public readonly creativeTypes = CreativeType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {item: Creative},
    private dialogRef: MatDialogRef<PreviewModalComponent>,
  ) {}

  public ngOnInit(): void {
    this.creative = this.data.item;
  }

  public cancel(): void {
    this.dialogRef.close();
  }

}
