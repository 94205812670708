import {Injectable} from '@angular/core';
import {StorageService} from '../services/local-storage.service';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/internal/operators';
import {Router} from '@angular/router';

@Injectable()
export class UnauthInterceptor implements HttpInterceptor {
  constructor(
    private localStorage: StorageService,
    private router: Router
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.localStorage.clear();
            this.router.navigate(['/login']);

            return throwError(error);
          }

          return throwError(error);
        })
      );
  }
}
