<div class="container preview">
  <div class="row">
    <div class="col-12">
      <div *ngIf="creative.type === creativeTypes.Image" class="preview__image-container">
        <img [src]="creative.src" class="preview__image">
      </div>
      <div *ngIf="creative.type === creativeTypes.Video" class="preview__video-container">
        <video [src]="creative.src" controls class="preview__video"></video>
      </div>
      <div class="d-flex justify-content-end mt-3 mb-3">
        <input type="button" value="Скасувати" class="btn btn-secondary mr-2" (click)="cancel()">
      </div>
    </div>
  </div>
</div>
