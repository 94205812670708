import { NgModule } from '@angular/core';
import {RouterModule, Route} from '@angular/router';
import {MainContainerComponent} from './core/components/main-container/main-container.component';
import {AuthGuard} from './core/guards/auth.guard';
import {ReportsModule} from './modules/reports/reports.module';
import {ConvertQueueModule} from './modules/convert-queue/convert-queue.module';

const routes: Route[] = [
  {
    path: 'main',
    component: MainContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivateChild: [AuthGuard],
        loadChildren: './modules/main-page/main-page.module#MainPageModule',
      },
      {
        path: 'creatives',
        canActivateChild: [AuthGuard],
        loadChildren: './modules/creatives/creatives.module#CreativesModule',
      },
      {
        path: 'playlists',
        canActivateChild: [AuthGuard],
        loadChildren: './modules/playlists/playlists.module#PlaylistsModule',
      },
      {
        path: 'reports',
        canActivateChild: [AuthGuard],
        loadChildren: './modules/reports/reports.module#ReportsModule',
      },
      {
        path: 'settings',
        canActivateChild: [AuthGuard],
        loadChildren: './modules/settings/settings.module#SettingsModule',
      },
      {
        path: 'logs',
        canActivateChild: [AuthGuard],
        loadChildren: './modules/logs/logs.module#LogsModule',
      },
      {
        path: 'convert-queue',
        canActivateChild: [AuthGuard],
        loadChildren: './modules/convert-queue/convert-queue.module#ConvertQueueModule',
      }
    ]
  },
  {
    path: 'sign-in',
    loadChildren: './modules/sign-in/sign-in.module#SignInModule',
  },
  {
    path: '**',
    redirectTo: '/main',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
