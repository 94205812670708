import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError, timer} from 'rxjs';
import {map as _map} from 'lodash';
import {environment} from '../../../environments/environment';
import {
  BackEndResponse, CalendarDay, CalendarHour, ConvertQueueItem, Creative, LogItem, PlayList, PlayListBlock,
  ReportData
} from '../interfaces/main.interfaces';
import {catchError, concatMap, map, takeWhile} from 'rxjs/internal/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public baseUrl = environment.baseUrl;
  public fetchingUnreadAmount = false;

  constructor(
    private httpClient: HttpClient
  ) {}

  public uploadImage(data, file: File): Observable<void> {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('duration', data.duration);
    formData.append('file', file);

    return this.httpClient.post<void>(`${this.baseUrl}/v1/creatives/create-image`, formData);
  }

  public uploadVideo(data, file: File): Observable<void> {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('file', file);

    return this.httpClient.post<void>(`${this.baseUrl}/v1/creatives/create-video`, formData);
  }

  public convertVideo(data, file: File): Observable<void> {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('file', file);

    return this.httpClient.post<void>(`${this.baseUrl}/v1/creatives/convert-video`, formData);
  }

  public getCreatives(pageNumber, field = 'id', isReverse = true): Observable<BackEndResponse<Creative[]>> {
    const params: {page: string, sort: string} = {
      page: pageNumber,
      sort: isReverse ? `-${field}` : field
    };

    return this.httpClient.get<BackEndResponse<Creative[]>>(`${this.baseUrl}/v1/creatives`, {params});
  }

  public removeCreative(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/v1/creatives/${id}`);
  }

  public getPlayLists(pageNumber, field = 'id', isReverse = true): Observable<BackEndResponse<PlayList[]>> {
    const params = {
      page: pageNumber,
      sort: isReverse ? `-${field}` : field
    };

    return this.httpClient.get<BackEndResponse<PlayList[]>>(`${this.baseUrl}/v1/playlists`, {params});
  }

  public getCalendar(): Observable<CalendarDay[]> {
    return this.httpClient.get<CalendarDay[]>(`${this.baseUrl}/v1/playlists/calendar`)
      .pipe(map((items: CalendarDay[]) => {
        return _map(items, (item: CalendarDay) => {
          return {
            date: moment(item.date),
            times: _map(item.times, (time: CalendarHour) => {
              return {
                ...time,
                datetime_stop: time ? moment(time.datetime_stop) : null,
                datetime_start: time ? moment(time.datetime_start) : null
              };
            })
          };
        });
      }));
  }

  public createPlayList(data: any): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/v1/playlists/create`, data);
  }

  public updatePlayList(data: any): Observable<void> {
    return this.httpClient.patch<void>(`${this.baseUrl}/v1/playlists/update`, data);
  }

  public getPlayListById(id: string): Observable<PlayList> {
    return this.httpClient.get<PlayList>(`${this.baseUrl}/v1/playlists/${id}`);
  }

  public removePlayList(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/v1/playlists/${id}`);
  }

  public getCurrentPlayList(): Observable<PlayList> {
    return this.httpClient.get<PlayList>(`${this.baseUrl}/v1/playlists/current`)
      .pipe(map((playlist: PlayList) => {
        if (!playlist) return playlist;

        return {
          ...playlist,
          datetime_stop: playlist ? moment(playlist.datetime_stop) : null,
          datetime_start: playlist ? moment(playlist.datetime_start) : null
        };
      }));
  }

  public getNextPlayList(): Observable<PlayList> {
    return this.httpClient.get<PlayList>(`${this.baseUrl}/v1/playlists/next`)
      .pipe(map((playlist: PlayList) => {
        if (!playlist) return playlist;

        return {
          ...playlist,
          datetime_stop: playlist ? moment(playlist.datetime_stop) : null,
          datetime_start: playlist ? moment(playlist.datetime_start) : null
        };
      }));
  }

  public getReportUrl(params: {from: string, to: string}): Observable<ReportData> {
    return this.httpClient.get<ReportData>(`${this.baseUrl}/v1/reports`, {params});
  }

  public createUser(data: {user_name: string, password: string}): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/v1/settings/new-user`, data);
  }

  public getCurrentBlock(): Observable<{data: PlayListBlock}> {
    return this.httpClient.get<{data: PlayListBlock}>(`${this.baseUrl}/v1/playlists/block-current-playlist`);
  }

  public getLogs(pageNumber): Observable<BackEndResponse<LogItem[]>> {
    const params = {
      page: pageNumber,
      sort: '-id'
    };

    return this.httpClient.get<BackEndResponse<LogItem[]>>(`${this.baseUrl}/v1/error-log`, {params});
  }

  public getQueueLog(pageNumber): Observable<BackEndResponse<ConvertQueueItem[]>> {
    const params = {
      page: pageNumber,
      sort: '-id'
    };

    return this.httpClient.get<BackEndResponse<ConvertQueueItem[]>>(`${this.baseUrl}/v1/log-convert`, {params})
      .pipe(map((response: BackEndResponse<ConvertQueueItem[]>) => {
        return {
          ...response,
          data: _map(response.data, (item: ConvertQueueItem) => {
            return {
              ...item,
              start: item.start ? moment(item.start) : null,
              stop: item.stop ? moment(item.stop) : null,
              created_at: item.created_at ? moment(item.created_at) : null
            };
          })
        }
      }));
  }

  public readLog(id: number): Observable<BackEndResponse<LogItem>> {
    return this.httpClient.patch<BackEndResponse<LogItem>>(`${this.baseUrl}/v1/error-log/${id}/set-read`, {});
  }

  public getAmountOfUnread(): Observable<{data: {quantity: string}}> {
    return this.httpClient.get<{data: {quantity: string}}>(`${this.baseUrl}/v1/error-log/quantity`);
  }

  public startFetchingUnreadAmount(): Observable<{data: {quantity: string}}> {
    this.fetchingUnreadAmount = true;

    return timer(0, 60000)
      .pipe(
        takeWhile(() => this.fetchingUnreadAmount),
        concatMap(() => this.getAmountOfUnread()),
        catchError((err: Error) => {
          this.stopFetchingUnreadAmount();
          return throwError(err);
        })
      );
  }

  public stopFetchingUnreadAmount(): void {
    this.fetchingUnreadAmount = false;
  }

  public editCreative(id: number, payload: {name: string}): Observable<void> {
    return this.httpClient.patch<void>(`${this.baseUrl}/v1/creatives/change-name/${id}`, payload);
  }
}
