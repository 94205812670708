import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {StorageService} from '../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild, CanActivate {
  constructor(
    private localStorage: StorageService,
    private router: Router
  ) {}

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkUserData();
  }

  public canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkUserData();
  }

  private checkUserData(): boolean {
    const userData = this.localStorage.getItem('userData');

    if (!userData) {
      this.router.navigate(['/sign-in']);

      return false;
    }

    return true;
  }
}
