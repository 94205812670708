import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {UserData} from '../interfaces/main.interfaces';
import {environment} from '../../../environments/environment';
import {map, tap} from 'rxjs/internal/operators';
import {StorageService} from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public baseUrl = environment.baseUrl;
  public userDataSubject = new ReplaySubject<UserData>(1);

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService
  ) {
    this.userDataSubject.next(storageService.getItem('userData'));
  }

  public getUserDataSubject(): Observable<UserData> {
    return this.userDataSubject.asObservable();
  }

  public clearUserData(): void {
    this.userDataSubject.next(null);
  }

  public signIn(data: {username: string, password: string}): Observable<UserData> {
    const payload = {...data, grant_type: 'password'};

    return this.httpClient.post<UserData>(`${this.baseUrl}/v1/oauth2/token`, payload)
      .pipe(map((response: UserData) => {
        const newData: UserData = {username: data.username, ...response};

        this.userDataSubject.next(newData);

        return newData;
      }));
  }

  public signOut(): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/v1/accounts/logout`, {})
      .pipe(tap(() => {
        this.userDataSubject.next(null);
        this.storageService.setItem('userData', null);
      }));
  }
}
