<nav class="navbar navbar-expand-lg navbar-dark bg-dark main-container">
  <a class="navbar-brand" routerLink="/main">
    Антошка
  </a>

  <div class="collapse navbar-collapse">
    <ul class="navbar-nav mr-auto">
      <li routerLink="/main/creatives"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          class="nav-item">
        <a class="nav-link">Креативи</a>
      </li>
      <li routerLink="/main/playlists"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          class="nav-item">
        <a class="nav-link">Плейлісти</a>
      </li>
      <li routerLink="/main/reports"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          class="nav-item">
        <a class="nav-link">Звіти</a>
      </li>
      <li routerLink="/main/settings"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          class="nav-item">
        <a class="nav-link">Налаштування</a>
      </li>
      <li routerLink="/main/convert-queue"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          class="nav-item">
        <a class="nav-link">Конвертації</a>
      </li>
      <li routerLink="/main/logs"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          class="nav-item">
        <a class="nav-link d-flex align-items-baseline">
          <span>Помилки</span>
          <span *ngIf="countOfUnread" class="unread-count">{{ countOfUnread }}</span>
        </a>
      </li>
    </ul>
    <div class="my-2 my-lg-0 d-flex align-items-center main-container__sign-out" (click)="signOut()">
      <span class="mr-2">
        {{ userData?.username}}
      </span>
      <span>
        <fa name="sign-out" size="2x"></fa>
      </span>
    </div>
  </div>
</nav>
<router-outlet></router-outlet>
