import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public setItem(key: string, item: any): void {
    localStorage.setItem(key, btoa(JSON.stringify(item)));
  }

  public getItem(key: string): any {
    const data = localStorage.getItem(key);
    if (!data) return null;

    return JSON.parse(atob(data));
  }

  public getAllItems(): Array<{key: string, value: string}> {
    const keys = Object.keys(localStorage);
    return new Array(keys.length).fill(null).map((e, i) => ({key: keys[i], value: localStorage.getItem(keys[i])}));
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public clear(): void {
    localStorage.clear();
  }
}
