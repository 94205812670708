<div class="container date-modal">
  <div class="row">
    <div class="col-12">
      <h3 class="h3">Вибрати дату</h3>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <div class="form-group">
        <dl-date-time-picker startView="day"
                             maxView="month"
                             [minView]="minView"
                             [(ngModel)]="currentDate">
        </dl-date-time-picker>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <div>{{ currentDate ? currentDate.format('YYYY-MM-DD HH:00') : '' }}</div>
        <input type="button" value="Зберегти" class="btn btn-primary mr-2" (click)="close()">
      </div>
    </div>
  </div>
</div>
