import {Injectable} from '@angular/core';
import {StorageService} from '../services/local-storage.service';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private localStorage: StorageService
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userData = this.localStorage.getItem('userData');
    if (!userData) return next.handle(request);

    return next.handle(
      request.clone({
        headers: request.headers.append('Authorization', `Bearer ${userData.access_token}`)
      })
    );
  }
}
