import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Moment} from 'moment';

@Component({
  selector: 'ant-date-picker-modal',
  templateUrl: './date-picker-modal.component.html'
})

export class DatePickerModalComponent implements OnInit {
  public currentDate: Moment = null;
  public minView = 'hour';

  constructor(
    private dialogRef: MatDialogRef<DatePickerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {date: Moment, disableHours: boolean}
  ) {}

  public ngOnInit(): void {
    this.currentDate = this.data.date;

    if (this.data.disableHours) {
      this.minView = 'day';
    }
  }

  public close(): void {
    this.dialogRef.close(this.currentDate);
  }
}
